
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { dark, syntaxHighlighterPrism } from "@mdx-deck/themes";
import { Box } from "theme-ui";
export const theme = { ...syntaxHighlighterPrism,
  ...dark
};
export const Highlighted = ({
  children
}) => <Box color="tomato" style={{
  display: "inline-block"
}} mdxType="Box">
    {children}
  </Box>;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Steps = makeShortcode("Steps");
const layoutProps = {
  theme,
Highlighted
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`ZKP Identity Infrastructure`}</h1>
    <p>{`Reimagining the future of identity infrastructures`}</p>
    <hr></hr>
    <h2>{`\\$ whoami`}</h2>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`Raymond Yeh`}</li>
        <li parentName="ul">{`Author @ geek.sg`}</li>
        <li parentName="ul">{`Software Developer @ GovTech`}</li>
        <li parentName="ul">{`Fellow @ RadicalXChange`}</li>
        <li parentName="ul">{`Verifiable Credentials, Identity, Blockchain, Zero-knowledge Proof`}</li>
      </ul>
    </Steps>
    <hr></hr>
    <h2>{`Agenda`}</h2>
    <ul>
      <li parentName="ul">{`Introduction to ZKP (5 min)`}</li>
      <li parentName="ul">{`Building apps with ZKP (5 min)`}</li>
      <li parentName="ul">{`Introduction to Semaphore (5 min)`}</li>
      <li parentName="ul">{`Use Cases of Semaphore (5 min)`}</li>
      <li parentName="ul">{`VeilOS Demo (5 min)`}</li>
      <li parentName="ul">{`Q&A (15 min)`}</li>
    </ul>
    <hr></hr>
    <h2>{`Introduction to ZKP`}</h2>
    <p>{`(5 min)`}</p>
    <hr></hr>
    <h2>{`The cave with magic door`}</h2>
    <img src="/assets/cave.png" style={{
      height: "60%"
    }} />
    <hr></hr>
    <h2>{`zk-SNARK`}</h2>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">
          <Highlighted mdxType="Highlighted">Zero-knowledge</Highlighted> - Does not reveal information beyond validity of statement itself
        </li>
        <li parentName="ul">
          <Highlighted mdxType="Highlighted">Succinct</Highlighted> - Proofs are small and verified quickly, even for huge programs
        </li>
        <li parentName="ul">
          <Highlighted mdxType="Highlighted">Non-interactive</Highlighted> - Proof is a single message sent from prover to verifier
        </li>
        <li parentName="ul">
          <Highlighted mdxType="Highlighted">Argument of Knowledge</Highlighted> - Argument that prover knows of certain secrets 
        </li>
      </ul>
    </Steps>
    <hr></hr>
    <h2>{`Building apps with ZKP`}</h2>
    <p>{`(5 min)`}</p>
    <hr></hr>
    <h2>{`Thinking in Circuits`}</h2>
    <img src="/assets/circuit.png" style={{
      height: "60%"
    }} />
    <hr></hr>
    <h2>{`Sample Use Case`}</h2>
    <p>{`An identity card to prove that one is above certain age without revealing the actual age.`}</p>
    <hr></hr>
    <h2>{`Age Check`}</h2>
    <img src="/assets/simple-age.png" style={{
      height: "60%"
    }} />
    <p>{`I know of an age that is legal to buy alcohol in Singapore?`}</p>
    <hr></hr>
    <h2>{`Age Check`}</h2>
    <img src="/assets/complete-age.png" style={{
      height: "60%"
    }} />
    <p>{`I know of a secret that proves that I'm a citizen of Singapore with an age above 18.`}</p>
    <hr></hr>
    <h2>{`Introduction to Semaphore`}</h2>
    <p>{`(5 min)`}</p>
    <hr></hr>
    <h2>{`Semaphore`}</h2>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`Users to prove membership of a set without revealing original identity`}</li>
        <li parentName="ul">{`Users to signal endorsement of an arbitrary string`}</li>
        <li parentName="ul">{`Mechanism to prevent double-signalling/double-spending`}</li>
      </ul>
    </Steps>
    <p><a parentName="p" {...{
        "href": "https://semaphore.appliedzkp.org/"
      }}>{`https://semaphore.appliedzkp.org/`}</a></p>
    <hr></hr>
    <h2>{`In Action`}</h2>
    <img src="/assets/semaphore-example.png" style={{
      height: "60%"
    }} />
    <hr></hr>
    <h2>{`State`}</h2>
    <img src="/assets/semaphore-externals.png" style={{
      height: "60%"
    }} />
    <hr></hr>
    <h2>{`Circuit`}</h2>
    <img src="/assets/semaphore-circuit.png" style={{
      height: "60%"
    }} />
    <hr></hr>
    <h2>{`Use Cases of Semaphore`}</h2>
    <p>{`(5 min)`}</p>
    <hr></hr>
    <h2>{`Financial Assistance`}</h2>
    <img src="/assets/uc-food.png" style={{
      height: "60%"
    }} />
    <hr></hr>
    <h2>{`Goods Rationing / Distribution`}</h2>
    <img src="/assets/uc-mask.png" style={{
      height: "60%"
    }} />
    <hr></hr>
    <h2>{`Fraud Prevention`}</h2>
    <img src="/assets/uc-fraud.png" style={{
      height: "60%"
    }} />
    <hr></hr>
    <h2>{`Voting`}</h2>
    <img src="/assets/uc-vote.png" style={{
      height: "60%"
    }} />
    <hr></hr>
    <h2>{`Whistleblowing`}</h2>
    <img src="/assets/uc-whistleblowing.png" style={{
      height: "60%"
    }} />
    <hr></hr>
    <h2>{`Membership Benefits`}</h2>
    <img src="/assets/uc-credit-card.png" style={{
      height: "60%"
    }} />
    <hr></hr>
    <h2>{`Student Benefits`}</h2>
    <img src="/assets/uc-student.png" style={{
      height: "60%"
    }} />
    <hr></hr>
    <h2>{`VeilOS Demo`}</h2>
    <p>{`(5 min)`}</p>
    <hr></hr>
    <h2>{`Q&A`}</h2>
    <p>{`(15 min)`}</p>
    <hr></hr>
    <h2>{`Additional Resources`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://link.springer.com/content/pdf/10.1007%2F0-387-34805-0_60.pdf"
        }}>{`How to Explain Zero-Knowledge Protocols to Your Children`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://z.cash/technology/zksnarks/"
        }}>{`What are zk-SNARKs`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://medium.com/@VitalikButerin/quadratic-arithmetic-programs-from-zero-to-hero-f6d558cea649"
        }}>{`Quadratic Arithmetic Programs: from Zero to Hero`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.zkproof.org/reference.pdf"
        }}>{`ZKProof Community Reference`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://semaphore.appliedzkp.org/"
        }}>{`Semaphore`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://geek.sg/blog/zkp-in-civic-tech%E2%80%8A-%E2%80%8Are-imagining-identity-infrastructures"
        }}>{`ZKP in Civic Tech - Re-imagining Identity Infrastructures`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://geek.sg/blog/decentralized-food-distribution-organisation"
        }}>{`Decentralized Food Distribution Organisation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://geek.sg/blog/veilos-preview-of-privacy-preserving-application-engine"
        }}>{`VeilOS - Preview of Privacy Preserving Application Engine`}</a></li>
    </ul>
    <hr></hr>
    <h2>{`END`}</h2>
    <ul>
      <li parentName="ul">{`Slides: zkp-id.decks.geek.sg`}</li>
      <li parentName="ul">{`Blog: geek.sg`}</li>
      <li parentName="ul">{`Twitter: @geek_sg`}</li>
      <li parentName="ul">{`LinkedIn: linkedin.com/in/raymondyeh`}</li>
    </ul>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;